export enum MailboxUserRole {
    READONLY = 'ReadOnly',
    READWRITE = 'ReadWrite',
}

export enum AccessStatus {
    PENDING = 'pending',
    ACCEPTED = 'accepted',
    DELETED = 'deleted',
    DECLINED = 'declined',
    EXPIRED = 'expired',
}
