import history from '../history';
// Interfaces
import { ISystemError } from '../interfaces/systemerror.interfaces';
import { IErrorCodeOptions, IMiddlewareOptions } from './interfaces';
import { ErrorContext } from '../enums/context';

export function isResponseStatusHandled(response: Response, errorCodeOptions?: IErrorCodeOptions) {
    return (
        errorCodeOptions &&
        errorCodeOptions.errorCodesHandled &&
        errorCodeOptions.errorCodesHandled.includes(response.status)
    );
}

export function redirectToSystemError(response: Response, errorCodeNotFound: number) {
    history.replace('/systemerror', {
        error: !errorCodeNotFound ? response.status : `${response.status} (${errorCodeNotFound})`,
        errorMessage: response.headers.get('x-transaction-id'),
    } as ISystemError);
}

export const getHeaders = (options?: object, isFormData = false) => {
    const token = sessionStorage.getItem('token'),
        headers = options ? { ...options } : {};

    if (!isFormData) {
        // default values
        headers['Content-Type'] = headers['Content-Type'] || 'application/json';
    }

    if (token && token !== 'undefined') {
        headers['Authorization'] = `Bearer ${token}`;
    }
    return headers;
};

/**
 * Ideally the token would be within the redux state. Loading the token from localstorage everytime is an expensive operation
 * This is a workaround to easily get RTK Queries up and running.
 * @returns
 */
export const getToken = (): string => {
    return sessionStorage.getItem('token');
};

export function getMiddlewareOptionsWithContext(errorContext: ErrorContext): IMiddlewareOptions {
    return { errorCodeOptions: { errorCodeComponentContext: errorContext, errorCodesHandled: [] } };
}
