import { IEmailEntity, IPhoneNumberEntity } from '../../interfaces/contact.interfaces';
import { IContacts, INotificationSettings } from '../../interfaces';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const profileSlice = createSlice({
    name: 'profile',
    initialState: {
        mobile: undefined,
        emails: undefined,
        notificationSettings: undefined,
        isProfileDataLoaded: undefined,
    },
    reducers: {
        setEmails: (draft, action: PayloadAction<IEmailEntity[]>) => {
            const { payload } = action;
            draft.emails = payload;
            draft.isProfileDataLoaded = draft.mobile !== undefined && draft.notificationSettings !== undefined;
        },
        setMobile: (draft, action: PayloadAction<IPhoneNumberEntity>) => {
            const { payload } = action;
            draft.mobile = payload;
            draft.isProfileDataLoaded = draft.emails !== undefined && draft.notificationSettings !== undefined;
        },
        setNotificationSettings: (draft, action: PayloadAction<INotificationSettings>) => {
            const { payload } = action;
            draft.notificationSettings = payload;
            draft.isProfileDataLoaded = draft.emails !== undefined && draft.mobile !== undefined;
        },
    },
});

export const { reducer: profileReducer } = profileSlice;
export const { setEmails, setMobile, setNotificationSettings } = profileSlice.actions;

export interface IProfileState extends IContacts, Omit<ReturnType<typeof profileReducer>, keyof IContacts> {}
