import React from 'react';
import { render } from 'react-dom';
import { ColorModeScript } from './ui-components';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';

// Components
import { ChakraRTLProvider } from './ui-components/chakraRTLProvider';
import { PortalType } from './components/PortalType/PortalType';
import Config from './config';
import Auth from './auth';
// Styles
import './styles/styles.scss';
// Redux
import store from './redux/store';
// Moment locales
import { updateLocale } from './updateLocale';
import 'moment/locale/da';
import 'moment/locale/ga';
import 'moment/locale/ar';
import 'moment/locale/en-ie';
import { theme } from './ui-components/theme';

render(
    <>
        <ColorModeScript initialColorMode={theme.config.initialColorMode} />
        <Provider store={store}>
            <ChakraRTLProvider>
                <PortalType>
                    <Auth>
                        <Config />
                    </Auth>
                </PortalType>
            </ChakraRTLProvider>
        </Provider>
    </>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
updateLocale();
