import React from 'react';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from '../redux';
import { ChakraProvider, extendTheme } from '.';
import { theme } from './theme';
import { ThemeDirection } from '@chakra-ui/react';

export const ChakraRTLProvider: FC = ({ children }) => {
    const extendedTheme = extendTheme(theme);
    const { currentLanguage } = useSelector(({ user }: IRootState) => ({ currentLanguage: user.currentLanguage }));
    const direction: ThemeDirection = currentLanguage?.isRightToLeft ? 'rtl' : 'ltr';
    extendedTheme.direction = direction;

    return (
        <ChakraProvider theme={extendedTheme} resetCSS>
            {children}
        </ChakraProvider>
    );
};
