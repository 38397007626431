import React, { lazy, Suspense } from 'react';

// Redux
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IRootState } from '../redux/interfaces';
import { setCurrentLanguage } from '../redux/user';
import { setSupportedLanguages, setGlobalAvailableLanguages } from '../redux/organization';
import { setConfigLoaded } from '../redux/appreadouts';
import { setPortalOrganizationId } from '../redux/portal';
// Interfaces
import { IBrandingConfiguration } from '../interfaces/portal.interfaces';
import { ILanguage } from '../interfaces/globalization.interfaces';
// Config
import { defaultConfig } from './default.config';
// Composers
import {
    ComposeBrandingLinks,
    ComposeBrandingTexts,
    ComposeBrandingLogos,
    ComposePortalFeatures,
    ComposeColorVariables,
} from './config.composer';
// Providers
import { getPortalBranding } from '../providers/portal.provider';
import { getPortalFeatures } from '../providers/feature.provider';
import { getUserInfo, updateUser } from '../providers/user.provider';
import { getGlobalAvailableLanguages } from '../providers/globalization.provider';
import { getOrganizationSupportedLanguages } from '../providers/organization.provider';
// Enums
import { portalType } from '../enums/portal';
// Context
import Context from './context';
// Helpers
import { isAuthenticated } from '../helpers/auth';
import { filterSupportedLanguages } from '../helpers/general';
import { parseQueryString } from '../helpers/queryString';
// Components
import Loader from '../components/Loader';
// Apps
const UserApp = lazy(() => import('../app/user/App'));
const AdminApp = lazy(() => import('../app/admin/App'));
const OrganizationApp = lazy(() => import('../app/organization/App'));

export const PortalContext = Context;

interface IPortalConfigState {
    isLoading: boolean;
    mappedPortalConfig: any;
    unmappedPortalConfig: IBrandingConfiguration;
    unmappedPortalFeatures: any;
    currentLanguage: ILanguage;
}

interface IQueryString {
    languageId?: string;
}
class PortalConfig extends React.Component<ReduxType, IPortalConfigState> {
    constructor(props: ReduxType) {
        super(props);
        this.state = {
            mappedPortalConfig: undefined,
            unmappedPortalConfig: undefined,
            unmappedPortalFeatures: undefined,
            isLoading: true,
            currentLanguage: undefined,
        };
    }

    componentDidMount() {
        this.initializeConfig();
    }

    componentDidUpdate() {
        if (
            !this.state.isLoading &&
            this.state.currentLanguage &&
            this.state.currentLanguage.id !== this.props.currentLanguage.id
        ) {
            this.setState({ currentLanguage: this.props.currentLanguage });
            this.composePortalConfig();
        }
    }

    initializeConfig = async () => {
        await this.exposePortalBranding();
        await this.exposePortalFeatures();
        await this.setPortalLanguages();
        this.composePortalConfig();
        this.setSizeGlobalVariables();
    };

    composePortalConfig = () => {
        const config: any = {};
        const unmappedConfig = this.state.unmappedPortalConfig;

        config.links = ComposeBrandingLinks(unmappedConfig.links, this.props.currentLanguage.id);
        config.logos = ComposeBrandingLogos(unmappedConfig.logos);
        config.texts = ComposeBrandingTexts(unmappedConfig.texts, defaultConfig.texts, this.props.currentLanguage.id);
        config.features = ComposePortalFeatures(this.state.unmappedPortalFeatures, defaultConfig.features);

        this.setGlobalColorVariables(ComposeColorVariables(this.state.unmappedPortalConfig.colors));
        this.setState({ mappedPortalConfig: config }, this.onPortalConfigMapped);
    };

    onPortalConfigMapped = () => {
        this.setState({ isLoading: false }, () => {
            this.props.setConfigLoaded(true);
        });
    };

    setGlobalColorVariables = (colorVariables: Map<string, string>) => {
        colorVariables.forEach((value, key) => {
            document.documentElement.style.setProperty(key, value);
        });
    };

    setGlobalAvailableLanguages = async () => {
        if (this.props.globalAvailableLanguages.length) return;
        const { items } = await getGlobalAvailableLanguages();
        this.props.setGlobalAvailableLanguages(items);
    };

    getLanguageIdFromQueryParams = () => {
        const queryString: IQueryString = parseQueryString(window.location.search);
        if (Object.keys(queryString).length) {
            return +queryString.languageId || null;
        } else {
            return null;
        }
    };

    setPortalLanguages = async () => {
        await this.setGlobalAvailableLanguages();

        // Set supported languages if portal branding config contains any.
        // Else, get organization supported languages and set them as default.
        const unmappedConfig = this.state.unmappedPortalConfig;
        if (unmappedConfig && unmappedConfig.languages.length) {
            const portalLanguages = filterSupportedLanguages(
                unmappedConfig.languages,
                this.props.globalAvailableLanguages
            );
            this.props.setSupportedLanguages(portalLanguages);
        } else {
            const organizationSupportedLanguages = await getOrganizationSupportedLanguages(
                this.props.portalOrganizationId
            );
            const organizationLanguages = filterSupportedLanguages(
                organizationSupportedLanguages.items,
                this.props.globalAvailableLanguages
            );
            this.props.setSupportedLanguages(organizationLanguages);
        }

        const userLanguageId = isAuthenticated()
            ? (await getUserInfo(+sessionStorage.getItem('userId'))).languageId
            : this.getLanguageIdFromQueryParams() || 1;

        const userLanguage = this.props.supportedLanguages.find((lang) => lang.id === userLanguageId);

        if (userLanguage) {
            this.props.setCurrentLanguage(userLanguage);
        } else {
            const fallbackLanguage = this.props.supportedLanguages[0];
            this.props.setCurrentLanguage(fallbackLanguage);
            updateUser({ languageId: fallbackLanguage.id });
        }

        this.setState({ currentLanguage: userLanguage });
    };

    exposePortalBranding = async () => {
        const brandingConfig = await getPortalBranding(this.props.portalId);
        this.props.setPortalOrganizationId(brandingConfig.organizationId);
        this.setState({ unmappedPortalConfig: brandingConfig });
    };

    exposePortalFeatures = async () => {
        const features = isAuthenticated() ? (await getPortalFeatures(this.props.portalId)).items : [];
        this.setState({ unmappedPortalFeatures: features });
    };

    setSizeGlobalVariables = () => {
        // Fix of vh on mobile explained here: https://dev.to/admitkard/mobile-issue-with-100vh-height-100-100vh-3-solutions-3nae
        document.documentElement.style.setProperty('--window-height', `${window.innerHeight}px`);

        window.addEventListener('resize', () => {
            document.documentElement.style.setProperty('--window-height', `${window.innerHeight}px`);
        });
    };

    renderLoader = () => {
        return (
            <div className="h-100 w-100 d-flex align-items-center justify-content-center">
                <Loader show size="lg" />
            </div>
        );
    };

    renderPortal(type: portalType) {
        switch (type) {
            case portalType.AdminPortal:
                return <AdminApp />;
            case portalType.OrganizationPortal:
                return <OrganizationApp />;
            default:
                return <UserApp />;
        }
    }

    renderApp = () => {
        const config = { ...this.state.mappedPortalConfig };
        return (
            <Suspense fallback={null}>
                <PortalContext.Provider value={config}>
                    {this.renderPortal(this.props.portalType)}
                </PortalContext.Provider>
            </Suspense>
        );
    };

    public render() {
        return this.state.isLoading ? this.renderLoader() : this.renderApp();
    }
}

const mapStateToProps = ({ portal, user, organization }: IRootState) => {
    return {
        portalId: portal.portalId,
        portalType: portal.portalType,
        portalOrganizationId: portal.portalOrganizationId,
        currentLanguage: user.currentLanguage,
        supportedLanguages: organization.supportedLanguages,
        globalAvailableLanguages: organization.globalAvailableLanguages,
    };
};

const mapDispatcherToProps = (dispatch: Dispatch) => {
    return {
        setConfigLoaded: (isConfigLoaded: boolean) => dispatch(setConfigLoaded(isConfigLoaded)),
        setCurrentLanguage: (currentLanguage: ILanguage) => dispatch(setCurrentLanguage(currentLanguage)),
        setSupportedLanguages: (languages: ILanguage[]) => dispatch(setSupportedLanguages(languages)),
        setGlobalAvailableLanguages: (languages: ILanguage[]) => dispatch(setGlobalAvailableLanguages(languages)),
        setPortalOrganizationId: (portalOrganizationId: number) =>
            dispatch(setPortalOrganizationId(portalOrganizationId)),
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>;

export default connect(mapStateToProps, mapDispatcherToProps)(PortalConfig);
