import { sendersApi, organizationApi } from '../app/admin/state';
import { notificationApi, userSettingsApi } from '../app/user/state';
import {
    adminPortalReducer,
    appReadoutsReducer,
    documentPreviewReducer,
    mailboxReducer,
    menuBarReducer,
    organizationReducer,
    portalReducer,
    profileReducer,
    sidebarReducer,
    userReducer,
    authReducer,
    sendersReducer,
} from './';

export const rootReducer = {
    [sendersApi.reducerPath]: sendersApi.reducer,
    [organizationApi.reducerPath]: organizationApi.reducer,
    [notificationApi.reducerPath]: notificationApi.reducer,
    [userSettingsApi.reducerPath]: userSettingsApi.reducer,
    adminPortal: adminPortalReducer,
    appReadouts: appReadoutsReducer,
    documentPreview: documentPreviewReducer,
    mailbox: mailboxReducer,
    menubar: menuBarReducer,
    organization: organizationReducer,
    portal: portalReducer,
    profile: profileReducer,
    sidebar: sidebarReducer,
    user: userReducer,
    auth: authReducer,
    senders: sendersReducer,
};
