import * as React from 'react';
// Redux
import { IRootState } from '../../redux/interfaces';
// Styles
import styles from './loader.module.scss';
// Components
import LoaderAnimation from '../../assets/preloaders/loader';
// Helpers
import { getGlobalCssVariables } from '../../helpers/general';
import { useSelector } from 'react-redux';

interface ILoaderProps {
    show: boolean;
    size: 'sm' | 'md' | 'lg';
    position?: string;
    className?: string;
}

export default function Loader(props: ILoaderProps) {
    const { isConfigLoaded } = useSelector(({ appReadouts }: IRootState) => appReadouts);

    const [colorPrimary, colorBlackRgb] = getGlobalCssVariables(['--color-primary', '--color-black-rgb']);
    const colorSemiTransparent = `rgb(${colorBlackRgb}, .1)`;
    return (
        props.show && (
            <LoaderAnimation
                className={genareteLoaderClasses(props.position, props.size, props.className)}
                fill={isConfigLoaded ? colorPrimary : colorSemiTransparent}
            />
        )
    );
}

const genareteLoaderClasses = (position: string, size: string, className: string): string => {
    const classNames = [styles.loader, styles[size], position ? styles[position] : ''];

    if (className) classNames.push(className);

    return classNames.join(' ');
};
